/*
Take date as a parameter (2024-08-23) and return day and month (Aug 23)
*/
export const formatDateToLocaleDateString = (dateStr: string) => {
  const date = new Date(dateStr);
  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "short",
  };
  const formattedDate = date.toLocaleDateString("en-GB", options);
  return formattedDate;
};

/**
 * Converts a date string from 'YYYY-MM-DD' format to 'DD MMM YYYY' format.
 * @param dateString - The date string in 'YYYY-MM-DD' format.
 * @returns The formatted date string in 'DD MMM YYYY' format.
 */
export const formatDateToString = (dateString: string): string => {
  if (!dateString) return "";
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "2-digit",
  };
  const formattedDate = date
    .toLocaleDateString("en-US", options)
    .replace(/,/, "");
  return formattedDate; // return "24 Oct 2024"
};

/**
 * Converts a Date object to YYYY-MM-DD format
 * @param dateInput - Date object or string
 */
export const getParamDateString = (dateInput: Date | string) => {
  const _date = new Date(dateInput);

  const year = _date.getFullYear();
  const month = String(_date.getMonth() + 1).padStart(2, "0");
  const day = String(_date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const calculateAge = (date: Date | string | undefined | null) => {
  if (!date) return 0;

  const today = new Date();
  const birthDate = new Date(date);
  let age = today.getFullYear() - birthDate.getFullYear();

  const isBeforeBirthdayThisYear =
    today.getMonth() < birthDate.getMonth() ||
    (today.getMonth() === birthDate.getMonth() &&
      today.getDate() < birthDate.getDate());

  if (isBeforeBirthdayThisYear) {
    age -= 1;
  }

  return age;
};
