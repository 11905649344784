//* Packages Import */
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

//* Redux */
import { getMaskedInfo, getTemplates } from "@Actions/lead";

//* Components Imports */
import Modal from "@Components/utility/Modal";
import SkeletonCard from "@Core/SkeletonLoader/Card";
import EmailModal from "@Components/TemplateSection/EmailModal";

//* Hook Imports */
import { useAppDispatch, useAppSelector } from "@Hooks/redux-hooks";

//* Types and Enums */
import { ITemplate } from "@Types/common";

export default function TemplateSection() {
  const dispatch = useAppDispatch();
  const { leadId } = useParams();

  const { list, loading } = useAppSelector((state) => state.lead.templates);
  const phone = useAppSelector((state) => state.lead.leadData?.phone);

  const isPhoneUnmasked = useAppSelector(
    (state) => state.lead.unMasking.isPhoneUnMasked,
  );

  const [showEmailModal, setShowEmailModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<ITemplate | null>(
    null,
  );

  const handleWhatsapp = async (message: string) => {
    let unmaskedPhone = phone;

    if (!isPhoneUnmasked) {
      const response: any = await dispatch(
        getMaskedInfo({ fieldNames: ["phone"] }),
      );
      unmaskedPhone = response?.payload[0]?.phone;
    }

    const WA_URL = `https://wa.me/${unmaskedPhone}?text=${encodeURIComponent(message)}`;
    window.open(WA_URL, "_blank");
  };

  const handleEmail = (template: ITemplate) => {
    setShowEmailModal(true);
    setSelectedTemplate(template);
  };

  useEffect(() => {
    dispatch(
      getTemplates({
        leadId: leadId,
      }),
    );
  }, []);

  return (
    <div className="p-4 flex flex-col gap-2">
      {loading ? (
        <SkeletonCard contentLines={0} listLength={4} />
      ) : list?.length > 0 ? (
        list.map((template, index) => (
          <div
            className="rounded-md border border-gray-border flex flex-col p-3 h-[140px] justify-between"
            key={template.id}
          >
            <div className="flex items-center gap-2">
              <span className="bg-black rounded-full p-2 w-5 h-5 text-xxs flex items-center justify-center text-white">
                {index + 1}
              </span>
              <h1 className="font-medium truncate">{template.title}</h1>
            </div>
            <span className=" text-gray text-sm -mt-1 font-light line-clamp-2">
              {template.description}
            </span>

            <div className="flex items-center text-xs mt-4 gap-2">
              {template.emailEnabled && (
                <div className="flex items-center gap-2">
                  <div
                    className="text-link cursor-pointer"
                    onClick={() => handleEmail(template)}
                  >
                    Send via Email
                  </div>
                  <span className="text-light-gray"> |</span>
                </div>
              )}

              {template.whatsappEnabled && (
                <div
                  className="text-link cursor-pointer"
                  onClick={() => handleWhatsapp(template.bodyWhatsapp)}
                >
                  Send via WhatsApp
                </div>
              )}
            </div>
          </div>
        ))
      ) : (
        <div className="flex items-center justify-center">
          <span className="text-xs text-gray">No templates found!</span>
        </div>
      )}

      {showEmailModal && (
        <Modal open={showEmailModal} onClose={() => setShowEmailModal(false)}>
          <EmailModal data={selectedTemplate} />
        </Modal>
      )}
    </div>
  );
}
