export const LOGIN_DATA_MAPPER = {
  user_id: "userId",
  user_email: "userEmail",
  assign_agent: "assignAgent",
  lead_tags: "leadTags",
  lead_details: "leadDetails",
  basic_details: "basicDetails",
  marketing_details: "marketingDetails",
  admission_details: "admissionDetails",
  academic_details: "academicDetails",
  loan_details: "loanDetails",
  coapplicant_details: "coapplicantDetails",
  kyc_documents: "kycDocuments",
  academic_documents: "academicDocuments",
  financial_documents: "financialDocuments",
  bulk_document_upload: "bulkDocumentUpload",
  loan_application: "loanApplication",
  application_status: "applicationStatus",
  application_tracking: "applicationTracking",
  repeated_lead: "repeatedLead",
  conversion_data: "conversionData",
  disbursal_data: "disbursalData",
  coapplicant_docs: "coapplicantDocs",
  bulk_document_download: "bulkDocumentDownload",
  mark_verify: "markVerify",
  doc_reupload: "docReupload",
  review_disbursal_doc: "reviewDisbursalDoc",
  application_info_bypass: "applicationInfoBypass",
};
