//* V1 endpoints need "/" at the end, V2 endpoints don't need it

// Base Paths
export const CRM_BASE_PATH = import.meta.env.VITE_CRM_URL;

export const UC_HOSTURL = import.meta.env.VITE_UC_HOSTURL;

// Auth
export const GOOGLE_AUTH_URL = "https://accounts.google.com/o/oauth2/v2/auth";

export const GOOGLE_REDIRECT_URI = `${CRM_BASE_PATH}/authorize`;

export const VERIFY_EMAIL_LOGIN = "crm/auth/";

export const SEND_EMAIL_OTP = "crm/login/send-otp";

export const VERIFY_EMAIL_OTP = "crm/login/verify-otp";

// App
export const SEARCH = "crm/search/";

export const ALL_TAGS = "crm/tags/";

export const ALL_PARTNERS = "crm/loan-partners";

export const ALL_COUNTRIES = "portfolio/countries";

export const LOST_REASONS = "crm/lost-reasons/";

export const UTM_SOURCES = "crm/utm-sources/";

export const UTM_MEDIUMS = "partners/utm-mediums/";

export const TEAM = "crm/agents/";

// Lead
export const LEAD_DETAILS = "crm/leads";

export const TEAM_MEMBERS = "crm/agents/team-members";

export const UPDATE_TAGS = (leadId: number) =>
  `crm/leads/${leadId}/toggle-tags`;

export const GET_NOTES = (leadId: string | number) =>
  `crm/leads/${leadId}/notes`;

export const GET_LEAD_ASSIGNEES = "crm/tasks/assignees";

export const DOCUMENTS = "crm/document";

export const UPDATE_DOCUMENT = "crm/document";

export const BULK_DOWNLOAD_DOC = (leadUUID: string) =>
  `crm/download-zip/${leadUUID}`;

export const GET_TIMELINE_LOGS = (leadId: number) => `crm/timeline/${leadId}`;

export const GET_DISBURSAL_LOGS = (leadId: number) =>
  `crm/leads/${leadId}/disbursal-data`;

export const GET_CONVERSION_LOGS = (leadId: number) =>
  `crm/leads/${leadId}/loan-applications/conversion`;

export const LEAD_DETAILS_SECTION = (leadId: number, formSection: string) =>
  `crm/leads/${leadId}/${formSection}`;

export const GET_TASKS = `crm/tasks`;

export const GET_TASK_CATEGORIES = `crm/taskcategories`;

export const ASSIGNMENT_STATUS = (leadId: string | number) =>
  `crm/leads/${leadId}/loan-applications/assignment-status`;

export const ASSIGNED_PARTNERS = (leadId: string | number) =>
  `crm/leads/${leadId}/loan-applications/assigned-partners`;

export const REGIONAL_HEADS = (partnerId: string | number) =>
  `crm/loan-partners/${partnerId}/regional-heads`;

export const PARTNER_MANAGERS = (partnerId: string | number) =>
  `crm/loan-partners/${partnerId}/lender-managers`;

export const LEAD_STATUS = "crm/status";

export const LEAD_STATUS_COUNT = "crm/leads-count";

export const APPLICATION_API = (leadId: string | number) =>
  `crm/leads/${leadId}/loan-applications`;

export const DISBURSAL_API = (leadId: number) =>
  `crm/leads/${leadId}/disbursal-data`;

export const GET_MY_TASKS = "crm/tasks/mytasks";

export const UPDATE_DISBURSAL_API = (leadId: number, disbursalId: number) =>
  `crm/leads/${leadId}/disbursal-data/${disbursalId}`;

export const UNMASKING = (leadId: number | string, fieldName: string) =>
  `crm/leads/${leadId}/pii-data?field_name=${fieldName}`;

export const GET_CITY_STATE = (pincode: string) =>
  `crm/geocode?zip-code=${pincode}`;

export const REVIEW_DISBURSMENT_DOCUMENT = (documentId: number) =>
  `crm/review-document/${documentId}/`;

export const GET_REVIEW_DOCUMENT = (contentType: string, instanceId: number) =>
  `crm/review-document?content_type=${contentType}&instance_id=${instanceId}`;

export const REPEATED_LEADS = (leadId: number) =>
  `crm/leads/${leadId}/repeated-leads`;

export const COPY_LEAD_DETAILS = (leadId: number) =>
  `crm/leads/${leadId}/copy-basic-details`;

export const UPDATE_LOAN_APPLICATION = (
  leadId: number,
  applicationId: number,
) => `crm/leads/${leadId}/loan-applications/${applicationId}`;

export const LEAD_TEMPLATES = (leadId: number) =>
  `crm/leads/${leadId}/templates`;
