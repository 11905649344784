//* Packages Imports */
import { useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";

//* Compponents Imports */
import Input from "@Core/Input";
import Radio from "@Core/Radio";
import Button from "@Core/Button";
import Select from "@Core/Select";
import PhoneInput from "@Core/PhoneInput";
import CurrencyInput from "@Core/CurrencyInput";
import CopyToClipboard from "@Components/utility/CopyToClipboard";
import DynamicFormDatePicker from "@Core/Datepicker/DynamicFormDatePicker";

//* Service Imports */
import debounce from "@Utils/debounce";
import { toastService } from "@Utils/toast";
import { calculateAge } from "@Utils/dateUtils";
import { getStructuredDate } from "@Utils/timeUtils";
import { useAppDispatch, useAppSelector } from "@Hooks/redux-hooks";
import { getCityState, setCoApplicantDetail } from "@Actions/lead/DetailsForm";

//* Data Imports */
import {
  BOOLEAN_OPTIONS,
  RELATION_ARRAY,
  CO_APPLICANT_PROFESSION,
} from "@Constants/leadDetailsData";

//* Assets Imports */
import { getCopyString } from "@Utils/common";
import Loading from "@Assets/images/loader.gif";
import { COPY_COAPPLICANT_MAPPER } from "@Mappers/lead";
import { MapBackendToFrontend } from "@Axios/mapping-framework";
import CoApplicantIdIcon from "@Assets/icons/CoApplicantId.png";

//* Types Imports */
import { ICoApplicantDetails } from "@Types/leads";
import {
  CityState,
  ICountryCode,
  IDatePickerSelection,
  ISelect,
} from "@Types/common";

//* Styles Imports */
import Styles from "@Components/pages/leads/lead-details/StudentDetailsSection/StudentDetailsSection.module.scss";

const CoApplicantForm = ({
  leadId,
  coApplicant,
  formIndex,
  removeCoApplicant,
}: {
  leadId: number;
  coApplicant: ICoApplicantDetails;
  formIndex: number;
  removeCoApplicant: (coApplicant: ICoApplicantDetails) => void;
}) => {
  const dispatch = useAppDispatch();
  const { access } = useAppSelector((state) => state.user);

  const isDisabled = !access?.detail.coapplicantDetails.change;

  const [isLoading, setIsLoading] = useState(false);
  const [fetchCityState, setfetchCityState] = useState("");
  const [employedOption, setEmployedOption] = useState(coApplicant.employed);

  const {
    control,
    reset,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<ICoApplicantDetails>({
    defaultValues: coApplicant,
  });

  const onSubmit = async (data: ICoApplicantDetails) => {
    setIsLoading(true);

    const updatedPayload = {
      ...data,
      ...(!data.employed && {
        income: undefined,
        profession: undefined,
      }),
    };

    if (data.dob && typeof data.dob !== "string") {
      updatedPayload.dob = getStructuredDate(data?.dob?.selectedDate);
    }

    const status: any = await dispatch(
      setCoApplicantDetail({
        leadId: leadId,
        payload: updatedPayload,
      }),
    );
    setIsLoading(false);

    //* Using this reset to set isDirty to false after form submission
    if (!status.error) {
      reset({ ...status.meta.arg.payload }, { keepDirty: false });
    }
  };

  const handlePincodeChange = debounce(async (pincode: string) => {
    if (!pincode) {
      setValue("city", "");
      setValue("state", "");
      return;
    }
    if (pincode.length !== 6) return;
    setIsLoading(true);
    setfetchCityState("Fetching City and State ...");

    try {
      const response: CityState | undefined = await getCityState(pincode);

      if (!response || !response.city || !response.state) {
        setValue("pincode", "");
      }
      setValue("city", response?.city || "");
      setValue("state", response?.state || "");
    } catch (error: any) {
      toastService.notify(
        "error",
        error.response.data.message || "Invalid Pincode",
      );
      setValue("pincode", "");
      setValue("city", "");
      setValue("state", "");
    } finally {
      setIsLoading(false);
      setfetchCityState("");
    }
  }, 500);

  const handleRemoveCoApplicant = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>,
  ) => {
    e.preventDefault();

    const confirmation = window.confirm(
      "Are you sure you want to remove this co-Applicant?",
    );

    if (confirmation) {
      removeCoApplicant(coApplicant);
    } else {
      return;
    }
  };

  const getCoApplicantAge = (dob: Date) => {
    if (!dob) return;

    const age = calculateAge(dob);
    if (age > 0) {
      setValue("age", age);
    }
  };

  const coApplicantCopyContent = useMemo(() => {
    const modelMapper = new MapBackendToFrontend(COPY_COAPPLICANT_MAPPER);

    const formattedData: Record<string, string> =
      modelMapper.mapBackendToFrontend({
        Name: coApplicant?.firstName + " " + coApplicant?.lastName || "",
        relation: (coApplicant?.relation as ISelect)?.value || "",
        phone:
          (coApplicant?.countryCode as ICountryCode)?.dialCode +
            coApplicant?.phone || "",
        email: coApplicant?.email || "",
        dob: (coApplicant?.dob as IDatePickerSelection)?.selectedDate || "",
        ongoingEmis:
          coApplicant?.ongoingEmis !== null ? coApplicant?.ongoingEmis : "",
        employed: coApplicant?.employed || "",
        pincode: coApplicant?.pincode || "",
        state: coApplicant?.state || "",
        city: coApplicant?.city || "",
        income: coApplicant?.income || "",
        profession: coApplicant?.profession?.label || "",
      });

    return getCopyString(formattedData);
  }, [coApplicant]);

  return (
    <div>
      <div className="flex relative gap-2 mb-7">
        <img src={CoApplicantIdIcon} alt="Coin Rupee" />
        <h3>Co-Applicant ({formIndex + 1}) Details</h3>
        {!coApplicant?.tempCoApplicant && (
          <div className="absolute right-0 top-[6px]">
            <CopyToClipboard content={coApplicantCopyContent} />
          </div>
        )}
      </div>
      <form key={coApplicant?.id} onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-2 gap-x-10 gap-y-4">
          <Controller
            name="firstName"
            control={control}
            rules={{ required: "First Name is required" }}
            render={({ field: { ref, ...field } }) => (
              <div className={errors?.firstName?.message && Styles.errorBorder}>
                <Input
                  {...field}
                  label="First Name"
                  labelPosition="left"
                  inputRef={ref}
                  mandatory
                  disabled={isDisabled}
                />
              </div>
            )}
          />
          <Controller
            name="lastName"
            control={control}
            rules={{ required: "Last Name is required" }}
            render={({ field: { ref, ...field } }) => (
              <div className={errors?.lastName?.message && Styles.errorBorder}>
                <Input
                  {...field}
                  label="Last Name"
                  labelPosition="left"
                  inputRef={ref}
                  mandatory
                  disabled={isDisabled}
                />
              </div>
            )}
          />
          <Controller
            name="relation"
            control={control}
            rules={{ required: "Relation is required" }}
            render={({ field: { onChange, value, ref, ...field } }) => (
              <div
                ref={ref}
                className={errors?.relation?.message && Styles.errorBorder}
              >
                <Select
                  {...field}
                  mandatory
                  label="Relation"
                  labelPosition="left"
                  options={RELATION_ARRAY}
                  setValue={onChange}
                  defaults={value}
                  disabled={isDisabled}
                />
              </div>
            )}
          />
          <Controller
            name="phone"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <div
                className={errors?.phone?.message && Styles.errorBorder}
                ref={ref}
              >
                <PhoneInput
                  {...field}
                  label="Phone Number"
                  inputDefaultValue={field.value}
                  countryCodeDefaultValue={coApplicant?.countryCode}
                  onCountryCodeSelect={(option: any) =>
                    setValue("countryCode", option, { shouldDirty: true })
                  }
                  onInputChange={(e) =>
                    setValue("phone", e.target.value, { shouldDirty: true })
                  }
                  disabled={isDisabled}
                />
              </div>
            )}
          />
          <Controller
            name="email"
            control={control}
            rules={{ required: "Email is required" }}
            render={({ field: { ref, ...field } }) => (
              <div className={errors?.email?.message && Styles.errorBorder}>
                <Input
                  {...field}
                  label="Email"
                  type="email"
                  labelPosition="left"
                  inputRef={ref}
                  mandatory
                  disabled={isDisabled}
                />
              </div>
            )}
          />
          <Controller
            name="dob"
            control={control}
            render={({ field: { ref, value, onChange, ...field } }) => (
              <div
                className={errors?.dob?.message && Styles.errorBorder}
                ref={ref}
              >
                <DynamicFormDatePicker
                  {...field}
                  label="DOB"
                  type="single"
                  labelPosition="left"
                  setSelectedDateObj={(obj) => {
                    onChange(getStructuredDate(obj.selectedDate));
                    getCoApplicantAge(obj.selectedDate as Date);
                  }}
                  defaultSelectedDateObj={value as IDatePickerSelection}
                  disabled={isDisabled}
                  disableMonthSelection={false}
                  disableYearSelection={false}
                  disableDatesAfter={new Date()}
                  hasError={
                    errors?.dob?.message
                      ? errors?.dob?.message?.length > 0
                      : false
                  }
                  helperText={
                    (getValues("age") ?? 0) > 0
                      ? `Age: ${getValues("age")} years`
                      : ""
                  }
                />
              </div>
            )}
          />
          <Controller
            name="ongoingEmis"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <CurrencyInput
                {...field}
                label="Ongoing Emis"
                defaultValue={field.value}
                labelPosition="left"
                disabled={isDisabled}
                inputRef={ref}
              />
            )}
          />
          <Controller
            name="employed"
            control={control}
            render={({ field: { onChange, value, ref, ...field } }) => (
              <div className={Styles.employed}>
                <p>Employed</p>
                <div ref={ref} className={Styles.employedOption}>
                  {BOOLEAN_OPTIONS.map((option) => (
                    <Radio
                      key={option.label}
                      {...field}
                      id={option.label}
                      label={option.label}
                      value={option.value as unknown as string}
                      checked={value === option.value}
                      handleChange={(value) => {
                        onChange(value);
                        setEmployedOption(value);
                      }}
                      disabled={isDisabled}
                    />
                  ))}
                </div>
              </div>
            )}
          />
          {employedOption && (
            <>
              <Controller
                name="income"
                control={control}
                rules={{ required: "Income is required" }}
                render={({ field: { ref, ...field } }) => (
                  <div
                    className={errors?.firstName?.message && Styles.errorBorder}
                  >
                    <CurrencyInput
                      {...field}
                      mandatory
                      label="Income"
                      labelPosition="left"
                      inputRef={ref}
                      defaultValue={field.value}
                      disabled={isDisabled}
                    />
                  </div>
                )}
              />
              <Controller
                name="profession"
                control={control}
                rules={{ required: "Profession is required" }}
                render={({ field: { onChange, value, ref, ...field } }) => (
                  <div ref={ref}>
                    <Select
                      {...field}
                      mandatory
                      label="Profession"
                      labelPosition="left"
                      options={CO_APPLICANT_PROFESSION}
                      setValue={onChange}
                      defaults={value}
                      disabled={isDisabled}
                    />
                  </div>
                )}
              />
            </>
          )}
          <Controller
            name="pincode"
            control={control}
            rules={{
              pattern: {
                value: /^\d{6}$/,
                message: "Invalid Pincode",
              },
            }}
            render={({ field: { ref, value, onChange, ...field } }) => (
              <Input
                {...field}
                label="Pin Code"
                labelPosition="left"
                value={value}
                disabled={isDisabled}
                inputRef={ref}
                infoText={fetchCityState}
                onChange={(e) => {
                  const newValue = e.target.value;
                  onChange(newValue);
                  handlePincodeChange(newValue);
                }}
              />
            )}
          />
          <Controller
            name="state"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <div ref={ref}>
                <Input
                  {...field}
                  label="State"
                  labelPosition="left"
                  disabled={true}
                  inputRef={ref}
                />
              </div>
            )}
          />
          <Controller
            name="city"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <div
                className={errors?.city?.message && Styles.errorBorder}
                ref={ref}
              >
                <Input
                  {...field}
                  label="City"
                  labelPosition="left"
                  disabled={true}
                  inputRef={ref}
                />
              </div>
            )}
          />
        </div>
        <div className="mt-12">
          {Object.keys(errors).length > 0 && (
            <span className="flex justify-end m-1 text-xs text-error">
              {errors.pincode?.message || "Please fill all required fields"}
            </span>
          )}
          <div className="flex justify-between gap-10">
            {access?.detail.coapplicantDetails.delete && (
              <Button
                type="button"
                variant="outline"
                font="sm"
                classes="w-[235px] !text-lost !border-lost"
                onClick={handleRemoveCoApplicant}
              >
                Remove Co-Applicant ({formIndex + 1})
              </Button>
            )}
            {!isDisabled && (
              <div className="w-full flex justify-end gap-2">
                {isLoading && (
                  <div className="flex items-center text-gray">
                    <img src={Loading} alt="loading" className="w-7 h-7" />
                    <span>Saving changes...</span>
                  </div>
                )}
                <Button
                  type="submit"
                  disabled={!isDirty || isLoading}
                  font="sm"
                  classes="w-40"
                >
                  Save
                </Button>
              </div>
            )}
          </div>
        </div>
        <hr className="border-t border-gray-border rounded-lg my-8" />
      </form>
    </div>
  );
};

export default CoApplicantForm;
