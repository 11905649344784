//* Packages Imports */
import { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";

//* Components Imports */
import SkeletonCard from "@Core/SkeletonLoader/Card";
import CopyToClipboard from "@Components/utility/CopyToClipboard";
import FormGenerator, { InputFieldsType } from "@Core/FormGenerator";

//* Service Imports */
import {
  getCityState,
  getLeadBasicDetails,
  setLeadBasicDetails,
} from "@Actions/lead/DetailsForm";
import { lead } from "@Slice/LeadSlice";
import { getCopyString } from "@Utils/common";
import { calculateAge } from "@Utils/dateUtils";
import { MapBackendToFrontend } from "@Axios/mapping-framework";
import { useAppDispatch, useAppSelector } from "@Hooks/redux-hooks";

//* Assets Imports */
import GraduatedIcon from "@Assets/icons/Graduated.png";

//* Types Imports */
import { CityState } from "@Types/common";
import { IBasicDetails } from "@Types/leads";
import { COPY_BASIC_DETAILS_MAPPER } from "@Mappers/lead";

const BasicDetails = () => {
  const copyRef = useRef("");

  const dispatch = useAppDispatch();
  const { isLoading, saveLoader, basicDetails } =
    useAppSelector(lead).leadDetails.basicDetailsObj;
  const { access } = useAppSelector((state) => state.user);

  const [leadAge, setLeadAge] = useState(0);
  const [fetchCityState, setfetchCityState] = useState("");
  const [cityState, setCityState] = useState<CityState>({
    pincode: "",
    city: "",
    state: "",
  });

  const isDisabled = !access?.detail.basicDetails.change;

  const { leadId } = useParams();

  const getBasicDefaultValues = useMemo(() => {
    if (!basicDetails) return {};
    if (cityState.city && cityState.state) {
      return {
        pincode: cityState.pincode,
        city: cityState.city,
        state: cityState.state,
      };
    }

    return {
      ...basicDetails,
      ...cityState,
    };
  }, [basicDetails, cityState]);

  const FormInputData: Array<InputFieldsType> = [
    {
      type: "text",
      name: "firstName",
      label: "First Name",
      required: true,
      disabled: isDisabled,
    },
    {
      type: "text",
      name: "lastName",
      label: "Last Name",
      required: true,
      disabled: isDisabled,
    },
    {
      type: "phone",
      name: "secondaryMobileNo",
      label: "Secondary Mob. no.",
      disabled: isDisabled,
      placeholder: "Phone Number",
      countryCodeName: "alternateCountryCode",
    },
    {
      type: "date",
      name: "dob",
      label: "DOB",
      datePickerType: "single",
      disabled: isDisabled,
      disableMonthSelection: false,
      disableYearSelection: false,
      disableDatesAfter: new Date(),
      helperText: leadAge > 0 ? `Age: ${leadAge} years` : "",
      callback(formObj) {
        setLeadAge(calculateAge(formObj.formDataObj.dob.selectedDate));
      },
    },
    {
      type: "text",
      name: "pincode",
      label: "Pincode",
      disabled: isDisabled,
      infoText: fetchCityState,
      callback(formObj) {
        fetchPincode(formObj);
      },
      pattern: "^\\d{6}$",
    },
    {
      type: "text",
      name: "state",
      label: "State",
      disabled: true,
    },
    {
      type: "text",
      name: "city",
      label: "City",
      disabled: true,
    },
  ];

  const inputFieldsData = useMemo(() => {
    return FormInputData;
  }, [fetchCityState, cityState, leadAge]);

  const fetchPincode = async (formObj: any) => {
    if (formObj?.formDataObj?.pincode === "") {
      setCityState({
        pincode: "",
        city: "-",
        state: "-",
      });
      return;
    }
    if (
      !formObj?.formDataObj?.pincode ||
      formObj.formDataObj.pincode.length !== 6
    )
      return;

    setfetchCityState("Fetching City and State ...");
    const response: CityState | undefined = await getCityState(
      formObj.formDataObj.pincode || "",
    );
    if (response) {
      setCityState({
        pincode: formObj.formDataObj.pincode,
        ...response,
      });
    }
    setfetchCityState("");
  };

  const handleFormSubmit = (formData: IBasicDetails) => {
    if (isNaN(Number(leadId))) return;

    const updatedFormData = { ...formData };
    if (basicDetails?.id) updatedFormData.id = basicDetails.id;
    if (!updatedFormData.pincode) {
      updatedFormData.city = "";
      updatedFormData.state = "";
    }

    dispatch(
      setLeadBasicDetails({
        leadId: Number(leadId),
        payload: updatedFormData,
      }),
    );
  };

  const getBasicDetailsCopyData = (data: IBasicDetails) => {
    const modelMapper = new MapBackendToFrontend(COPY_BASIC_DETAILS_MAPPER);

    const formattedData: Record<string, string> =
      modelMapper.mapBackendToFrontend({
        firstName: data?.firstName || "",
        lastName: data?.lastName || "",
        phoneWithCode: data?.phoneWithCode || "",
        pincode: data?.pincode || "",
        state: data?.state || "",
        city: data?.city || "",
      });

    return getCopyString(formattedData);
  };

  useEffect(() => {
    setCityState({
      pincode: basicDetails?.pincode || "",
      city: basicDetails?.city || "",
      state: basicDetails?.state || "",
    });

    copyRef.current = getBasicDetailsCopyData(basicDetails);

    if (basicDetails?.dob?.selectedDate)
      setLeadAge(calculateAge(basicDetails.dob.selectedDate));
  }, [basicDetails]);

  useEffect(() => {
    if (leadId && !basicDetails?.id)
      dispatch(getLeadBasicDetails(Number(leadId)));
  }, []);

  return (
    <div>
      <div className="flex relative gap-2 mb-7">
        <img src={GraduatedIcon} alt="Graduated Icon" />
        <h3>Basic Details Form</h3>
        <div className="absolute right-0 top-[6px]">
          <CopyToClipboard content={copyRef.current} />
        </div>
      </div>
      {isLoading ? (
        <SkeletonCard contentLines={9} gridCol={2} />
      ) : (
        <FormGenerator
          inputFieldsData={inputFieldsData}
          onSubmit={(formData) => handleFormSubmit(formData)}
          defaultValues={getBasicDefaultValues}
          gridCol={2}
          hideSubmitButton={isDisabled}
          disableSubmitBtn={saveLoader}
        />
      )}
    </div>
  );
};

export default BasicDetails;
