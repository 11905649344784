//* Package Imports */
import {
  InputHTMLAttributes,
  TextareaHTMLAttributes,
  useEffect,
  useRef,
} from "react";
import clsx from "clsx";
import { RefCallBack } from "react-hook-form";

//* Styles */
import Styles from "./Input.module.scss";

//* Interface */
interface BaseInputProps {
  label?: string;
  className?: string;
  variant?: "solid" | "error";
  multiline?: boolean;
  labelPosition?: "top" | "left";
  inputRef?: RefCallBack | null;
  helperText?: string | any;
  dataAttributes?: Record<string, string>;
  mandatory?: boolean;
  infoText?: string;
  disableEnter?: boolean;
}

interface InputProps
  extends InputHTMLAttributes<HTMLInputElement>,
    BaseInputProps {}

interface TextareaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement>,
    BaseInputProps {}

type CustomProps = InputProps & TextareaProps;

const Input = (props: CustomProps) => {
  const {
    label,
    className,
    variant = "solid",
    multiline = false,
    labelPosition = "top",
    inputRef,
    helperText,
    dataAttributes = {},
    mandatory,
    infoText = "",
    disableEnter = true,
    ...rest
  } = props;

  const preventWheelAction = (event: any) => {
    event.target.blur();
  };

  const inputRefFormGen = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (rest.defaultValue && inputRefFormGen.current) {
      inputRefFormGen.current.value = rest.defaultValue as string;
    }
  }, [rest.defaultValue]);

  return (
    //* Don't change the structure of the div, it's for helper texts*/
    <div>
      <div
        className={clsx(Styles.inputContainer, {
          [Styles.labelLeft]: labelPosition === "left",
        })}
      >
        {label && (
          <label htmlFor={props.id} className={Styles.label}>
            {mandatory && <span className="text-error mr-[2px]">*</span>}
            {label}
          </label>
        )}
        {multiline ? (
          <textarea
            ref={inputRef}
            className={clsx(Styles.input, Styles[variant], className)}
            {...rest}
          />
        ) : (
          <input
            ref={inputRef ? inputRef : inputRefFormGen}
            className={clsx(Styles.input, Styles[variant], className)}
            onWheel={preventWheelAction}
            onKeyDown={(e) => {
              if (e.key === "Enter" && disableEnter) {
                e.preventDefault();
              }
            }}
            {...rest}
          />
        )}
      </div>
      {infoText?.length !== 0 && (
        <span
          className={clsx(Styles.InfoLoader, {
            [Styles.labelLeftHelper]: labelPosition === "left",
          })}
        >
          {infoText}
        </span>
      )}
      {helperText && (
        <span
          className={clsx(Styles.helperText, {
            [Styles.labelLeftHelper]: labelPosition === "left",
          })}
          data-cy={dataAttributes["error-data-cy"]}
        >
          {helperText}
        </span>
      )}
    </div>
  );
};

export default Input;
