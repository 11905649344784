//* Package Imports */
import { useEffect } from "react";

const useChangePageTitle = (pageTitle: string | undefined) => {
  useEffect(() => {
    if (!pageTitle) return;
    const prevTitle = document.title;
    document.title = pageTitle || "UniCreds - CRM";

    return () => {
      document.title = prevTitle;
    };
  }, [pageTitle]);
};

export default useChangePageTitle;
