import { IStatusInfo, StatusKey } from "@Types/common";

export const STATUS_COLOR: Record<StatusKey, IStatusInfo> = {
  CREATED: {
    label: "Created",
    color: "bg-created",
    border: "border-created",
  },
  CONTACTED: {
    label: "Contacted",
    color: "bg-contacted",
    border: "border-contacted",
  },
  OPPORTUNITY: {
    label: "Opportunity",
    color: "bg-opportunity",
    border: "border-opportunity",
  },
  PROCESSING: {
    label: "Processing",
    color: "bg-contacted",
    border: "border-contacted",
  },
  IMPORTANT: {
    label: "Important",
    color: "bg-contacted",
    border: "border-contacted",
  },
  NEW_LEADS: {
    label: "New leads",
    color: "bg-contacted",
    border: "border-contacted",
  },
  FOLLOW_UPS: {
    label: "Follow up",
    color: "bg-contacted",
    border: "border-contacted",
  },
  DOCS_PENDING: {
    label: "Docs pending",
    color: "bg-docs-pending",
    border: "border-docs-pending",
  },
  LOGGED_IN: {
    label: "Logged in",
    color: "bg-logged-in",
    border: "border-logged-in",
  },
  SANCTIONED: {
    label: "Sanctioned",
    color: "bg-sanctioned",
    text: "text-black",
    border: "border-sanctioned",
  },
  PF_CONFIRMED: {
    label: "PF Confirmed",
    color: "bg-pf-confirmed",
    border: "border-pf-confirmed",
  },
  DISBURSED: {
    label: "Disbursed",
    color: "bg-disbursed",
    border: "border-disbursed",
  },
  LOST: {
    label: "Lost",
    color: "bg-lost",
    border: "border-lost",
  },
  REJECTED: {
    label: "Rejected",
    color: "bg-lost",
    border: "border-lost",
  },
  CROSS_SALE_IN_PROCESS: {
    label: "CROSS SALES",
    color: "bg-contacted",
    border: "border-contacted",
  },
  READY_TO_LOGIN: {
    label: "Ready to Login",
    color: "bg-ready-to-login",
    border: "border-ready-to-login",
  },
  LEAD_FORWARDED: {
    label: "Lead Forwarded",
    color: "bg-initial-stages",
  },
  WITHDRAWN: {
    label: "Withdrawn",
    color: "bg-black",
  },
  YET_TO_CONNECT: {
    label: "Yet to Connect",
    color: "bg-created",
  },
  UNDER_DISCUSSION: {
    label: "Under Discussion",
    color: "bg-contacted",
  },
  FUTURE_DATE: {
    label: "Future Date",
    color: "bg-contacted",
  },
  NOT_RESPONDING: {
    label: "Not Responding/Did not pick",
    color: "bg-contacted",
  },
  AWAITING_DOCS: {
    label: "Awaiting Docs",
    color: "bg-contacted",
  },
  DISBURSEMENT: {
    label: "Disbursal",
    color: "bg-disbursed",
  },
  NO_PRODUCT_OR_NOT_ELIGIBLE: {
    label: "No Product/Not Eligible",
    color: "bg-lost",
  },
  PAID: {
    label: "PAID",
    color: "bg-success",
  },
  NOT_PAID: {
    label: "NOT PAID",
    color: "bg-error",
  },
  PARTIALLY_PAID: {
    label: "PARTIALLY PAID",
    color: "bg-warning",
  },
  PRE_QUALIFIED: {
    label: "Prequalified",
    color: "bg-contacted",
    border: "border-contacted",
  },
  LQT_LOST: {
    label: "LQT Lost",
    color: "bg-lost",
    border: "border-lost",
  },
  ACTIVE: {
    label: "ACTIVE",
    color: "bg-success",
  },
  CANCELLED: {
    label: "CANCELLED",
    color: "bg-error",
  },
  RENEWED: {
    label: "RENEWED",
    color: "bg-created",
  },
  REPEATED: {
    label: "REPEATED",
    color: "bg-error",
  },
  REPEATED_TAG: {
    label: "Repeated",
    text: "text-error",
    color: "bg-error/10",
  },
  LQT_QUALIFIED_TAG: {
    label: "LQT Qualified",
    text: "text-created",
    color: "bg-created/10",
  },
};
