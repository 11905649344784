//* Package Imports */
import {
  InputHTMLAttributes,
  TextareaHTMLAttributes,
  useEffect,
  useRef,
  useState,
} from "react";
import clsx from "clsx";
import { RefCallBack } from "react-hook-form";

//* Service Imports */
import { formatCurrency } from "@Utils/common";

//* Styles Imports */
import Styles from "@Core/Input/Input.module.scss";

//* Interface */
interface BaseInputProps {
  label?: string;
  className?: string;
  variant?: "solid" | "error";
  labelPosition?: "top" | "left";
  inputRef?: RefCallBack | null;
  helperText?: string | any;
  dataAttributes?: Record<string, string>;
  mandatory?: boolean;
  disableEnter?: boolean;
  currencyType?: string;
  currencyLocale?: string;
  hideCurrencySymbol?: boolean;
}

interface InputProps
  extends InputHTMLAttributes<HTMLInputElement>,
    BaseInputProps {}

interface TextareaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement>,
    BaseInputProps {}

type CustomProps = InputProps & TextareaProps;

const CurrencyInput = (props: CustomProps) => {
  const {
    label,
    className,
    variant = "solid",
    labelPosition = "top",
    inputRef,
    helperText,
    dataAttributes = {},
    mandatory,
    onChange,
    disableEnter = true,
    ...rest
  } = props;

  const [inputValue, setInputValue] = useState<string>("");
  const [formattedCurrency, setFormattedCurrency] = useState<string>("");

  const inputRefFormGen = useRef<HTMLInputElement | null>(null);

  const preventWheelAction = (event: any) => {
    event.target.blur();
  };

  const handleInputChange = (value: string) => {
    setInputValue(value);
    setFormattedCurrency(
      formatCurrency(
        parseInt(value),
        true,
        props.currencyType,
        props.currencyLocale,
      ),
    );
  };

  useEffect(() => {
    if (rest.defaultValue && inputRefFormGen.current) {
      inputRefFormGen.current.value = rest.defaultValue as string;
    }
    handleInputChange(rest.defaultValue as string);
  }, [rest.defaultValue]);

  return (
    //* Don't change the structure of the div, it's for helper texts*/
    <div>
      <div
        className={clsx(Styles.inputContainer, {
          [Styles.labelLeft]: labelPosition === "left",
        })}
      >
        {label && (
          <label htmlFor={props.id} className={Styles.label}>
            {mandatory && <span className="text-error mr-[2px]">*</span>}
            {label}
          </label>
        )}
        <input
          ref={inputRef ? inputRef : inputRefFormGen}
          type="number"
          className={clsx(Styles.input, Styles[variant], className)}
          value={inputValue}
          onChange={(e) => {
            handleInputChange(e.target.value);
            if (onChange) {
              onChange(e);
            }
          }}
          onWheel={preventWheelAction}
          onKeyDown={(e) => {
            if (e.key === "Enter" && disableEnter) {
              e.preventDefault();
            }
          }}
          {...rest}
        />
      </div>
      {formattedCurrency?.length !== 0 && (
        <span
          className={clsx(Styles.InfoLoader, {
            [Styles.labelLeftHelper]: labelPosition === "left",
          })}
        >
          {formattedCurrency}
        </span>
      )}
      {helperText && (
        <span
          className={clsx(Styles.helperText, {
            [Styles.labelLeftHelper]: labelPosition === "left",
          })}
          data-cy={dataAttributes["error-data-cy"]}
        >
          {helperText}
        </span>
      )}
    </div>
  );
};

export default CurrencyInput;
