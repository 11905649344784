//* Packages */
import { useState } from "react";

//* Redux */
import { getMaskedInfo } from "@Actions/lead";

//* Components */
import Button from "@Core/Button";
import Input from "@Core/Input";

//* Hooks */
import { useAppDispatch, useAppSelector } from "@Hooks/redux-hooks";

//* Types & Enums
import { ITemplate } from "@Types/common";

interface IEmailModalProps {
  data: ITemplate | null;
}

export default function EmailModal({ data }: IEmailModalProps) {
  const dispatch = useAppDispatch();

  const userMail = useAppSelector((state) => state.user.email);
  const leadMail = useAppSelector((state) => state.lead.leadData?.email);

  const [body, setBody] = useState(data?.bodyEmail);

  const isEmailUnmasked = useAppSelector(
    (state) => state.lead.unMasking.isEmailUnMasked,
  );

  const pushToGmail = async () => {
    let unmaskedMail = leadMail;
    if (!isEmailUnmasked) {
      const response: any = await dispatch(
        getMaskedInfo({ fieldNames: ["email"] }),
      );
      unmaskedMail = response?.payload[0]?.email;
    }
    const url = `https://mail.google.com/mail/u/${userMail || 0}/?&view=cm&fs=1&tf=1&to=${unmaskedMail}&su=${data?.subjectEmail}&body=${encodeURIComponent(body || "")}`;
    window.open(url, "_blank");
  };

  return (
    <div className="h-[450px] w-[573px] flex flex-col justify-between">
      <span className="text-xl font-medium">{data?.title}</span>
      <div className="flex flex-col gap-4">
        <Input label="Title" labelPosition="top" value={data?.title} disabled />
        <Input
          label="Subject"
          labelPosition="top"
          value={data?.subjectEmail}
          disabled
        />
        <Input
          label="Body"
          labelPosition="top"
          value={body}
          multiline
          onChange={(e) => setBody(e.target.value)}
          rows={8}
        />
      </div>

      <div className="flex items-center justify-end">
        <Button
          variant="solid"
          onClick={pushToGmail}
          font="xs"
          classes="w-[160px] h-[34px]"
        >
          Send
        </Button>
      </div>
    </div>
  );
}
