import { MetaDocument } from "@Types/documents";
import { toastService } from "@Utils/toast";

export async function copyToClipboard(content: string | number) {
  if (!content) {
    console.error("No content found!");
    toastService.notify("warning", "No content found!");
    return;
  }

  try {
    await navigator.clipboard.writeText(content.toString());
    toastService.notify("success", "Copied to clipboard");
  } catch (err) {
    console.error("Failed to copy:", err);
    toastService.notify("error", "Something went wrong, please try again!");
  }
}

export const loadDynamicScript = (
  url: string,
  callback?: any,
  onError?: any,
) => {
  const script = document.createElement("script");
  script.src = url;
  script.defer = true;
  script.onload = callback;
  script.onerror = onError;
  document.body.appendChild(script);
};

export const generateRandomString = (length: number) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

export function formatCurrency(
  value: number | undefined | null,
  hideCurrencySymbol: boolean = false,
  currency: string = "INR",
  locale: string = "en-IN",
): string {
  if (value) {
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      currencyDisplay: hideCurrencySymbol ? "code" : "symbol",
    })
      .format(value)
      .replace(currency, "")
      .trim();
  }
  return "";
}

export const filterObjects = (data: any, filter: any, filterKey: string) => {
  return data.filter((item: any) => filter.includes(item[filterKey]));
};

/*
Safely HTML unescapes chars escaped by security 
middleware in Backend but required on Frontend
*/
export const safeHtmlUnescape = (input: string) => {
  return input
    .replace(/&amp;/gm, "&")
    .replace(/&#39;/gm, "'")
    .replace(/&quot;/gm, '"')
    .replace(/&lt;/gm, "<")
    .replace(/&gt;/gm, ">");
};

export const scrollToDiv = (id: string) => {
  const scrollElement = document.getElementById(id);
  if (scrollElement) {
    scrollElement.scrollIntoView({ behavior: "smooth" });
    return false;
  }
};

export const formatArrayToCopy = (
  arr: {
    label: string;
    value: string | number;
  }[],
) => {
  return arr.map((item) => `${item.label}: ${item.value}`).join("\n");
};

export const capitalizeText = (
  input: string,
  type: "first" | "all" = "first",
) => {
  if (type === "all") return input.toLocaleUpperCase();

  return input.charAt(0).toLocaleUpperCase() + input.slice(1);
};

export const convertToTitleCase = (
  input: string | null,
  delimiter = "_",
): string => {
  if (!input) return "";
  return input
    .split(delimiter)
    .map((word) => capitalizeText(word))
    .join(" ");
};

export const mergeDocumentsWithMeta = (docs: any[], meta: MetaDocument[]) => {
  if (!meta?.length) return [];
  return meta.map((metaItem) => {
    const doc =
      (docs?.length &&
        docs.find((doc) => doc.documentType === metaItem.documentType)) ||
      {};
    return {
      ...metaItem,
      ...doc,
    };
  });
};

export const downloadFile = async (url: string, filename: string) => {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Failed to fetch file: ${response.statusText}`);
    }

    const blob = await response.blob();

    const blobUrl = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = blobUrl;
    link.download = filename;

    document.body.appendChild(link);
    link.click();

    URL.revokeObjectURL(blobUrl);
    document.body.removeChild(link);
  } catch (error) {
    toastService.notify("error", "Error downloading file");
    console.error("Error downloading file:", error);
  }
};

export const getCopyString = (ob: Record<string, string>) => {
  return Object.entries(ob)
    .map(([key, value]) => `${key}: ${value}`)
    .join("\n");
};
