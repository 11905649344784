//* Package Imports */
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";

//* Component Imports */
import CustomDateRangePicker from "@Core/Datepicker";
import { formatDate, getTimeFromDate } from "@Core/Datepicker/utils";
import TimeInput from "@Core/TimeInput";

//* Utils Imports */
import { IDatePickerSelection, ITimeInputData } from "@Types/common";

//* Assets Imports */
import CalendarOutline from "@Assets/icons/CalendarOutline.png";

//* Styles Imports */
import Styles from "@Core/Datepicker/CustomDateRangePicker.module.scss";

const DynamicFormDatePicker = ({
  label,
  setSelectedDateObj,
  type,
  defaultSelectedDateObj,
  hasError,
  placeholder = "Select date",
  showClearDates = false,
  disabled = false,
  mandatory = false,
  name,
  labelPosition = "top",
  showTime,
  disablePreviousDates = false,
  disableMonthSelection = true,
  disableYearSelection = true,
  disableDatesAfter,
  disabledDates,
  helperText,
}: {
  name: string;
  showNeighbouringMonth?: boolean;
  type?: "single" | "range";
  disablePreviousDates?: boolean;
  setSelectedDateObj: (dates: IDatePickerSelection) => void;
  defaultSelectedDateObj?: IDatePickerSelection;
  disableMonthSelection?: boolean;
  disableYearSelection?: boolean;
  showClearDates?: boolean;
  showTime?: boolean;
  placeholder?: string;
  hasError?: boolean;
  disabled?: boolean;
  label?: string;
  mandatory?: boolean;
  labelPosition?: "left" | "top";
  disableDatesAfter?: Date;
  disabledDates?: string[];
  helperText?: string;
}) => {
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [showDateDropdown, setShowDateDropdown] = useState(false);
  const [time, setTime] = useState<ITimeInputData | null>(null);

  const fieldValueRef = useRef<HTMLInputElement | null>(null);
  const selectedDate = useRef({
    label: defaultSelectedDateObj?.startDate
      ? formatDate(defaultSelectedDateObj?.startDate)
      : "",
    value: defaultSelectedDateObj?.startDate || "",
  });
  const startDate = useRef({
    label: defaultSelectedDateObj?.startDate
      ? formatDate(defaultSelectedDateObj?.startDate)
      : "",
    value: defaultSelectedDateObj?.startDate || "",
  });
  const endDate = useRef({
    label: defaultSelectedDateObj?.endDate
      ? formatDate(defaultSelectedDateObj?.endDate)
      : "",
    value: defaultSelectedDateObj?.endDate || "",
  });
  const pickedDate = useRef({
    startDate: "",
    endDate: "",
  });

  pickedDate.current.startDate =
    (type === "single"
      ? (selectedDate.current.value as string)
      : (startDate.current.value as string)) ||
    (defaultSelectedDateObj?.startDate as string);
  pickedDate.current.endDate =
    (endDate.current.value as string) ||
    (defaultSelectedDateObj?.endDate as string);

  const noOfDays = useRef(0);

  const handleSubmitDates = (time: ITimeInputData | null) => {
    if (showTime && !time) return;

    if (type === "single" && selectedDate.current.value) {
      const modifiedDate = new Date(selectedDate.current.value);
      modifiedDate.setHours(time?.hours || 0, time?.minutes || 0, 0, 0);

      setSelectedDateObj({
        selectedDate: modifiedDate as Date,
      });
      selectedDate.current.value = modifiedDate;
      selectedDate.current.label = formatDate(modifiedDate);

      if (fieldValueRef.current) {
        fieldValueRef.current.value = `${selectedDate.current.label}  ${
          showTime ? time?.label : ""
        }`;
      }
    } else if (
      type === "range" &&
      startDate.current.value &&
      endDate.current.value
    ) {
      setSelectedDateObj({
        startDate: startDate.current.value as Date,
        endDate: endDate.current.value as Date,
      });
      if (fieldValueRef.current) {
        fieldValueRef.current.value = `${startDate.current.label}${endDate.current.label}`;
      }
    }
  };

  const handleChooseDate = (obj: any) => {
    if (obj?.selectedDate) {
      selectedDate.current.value = obj.selectedDate;
      selectedDate.current.label = formatDate(obj.selectedDate);
      handleSubmitDates(time);
    } else if (obj.startDate && obj.endDate) {
      startDate.current.value = obj.startDate || "";
      endDate.current.value = obj.endDate || "";
      noOfDays.current = obj.noOfDaysSelected || 0;
      startDate.current.label = obj?.startDate
        ? `${formatDate(obj.startDate)} - `
        : "";
      endDate.current.label = obj?.endDate ? formatDate(obj.endDate) : "";
      handleSubmitDates(time);
    }
  };

  const getInitialFieldValue = () => {
    let fieldLabel = "";

    if (type === "single" && startDate.current.label) {
      fieldLabel = startDate.current.label;

      if (showTime) {
        fieldLabel += `  ${getTimeFromDate(startDate.current.value)}`;
      }
    } else if (
      type === "range" &&
      startDate.current.label &&
      endDate.current.label
    ) {
      fieldLabel = `${startDate.current.label} - ${endDate.current.label}`;
    }

    if (fieldValueRef.current && fieldLabel)
      fieldValueRef.current.value = fieldLabel;
  };

  useEffect(() => {
    if (showDateDropdown) {
      const handleDropdownClick = (event: MouseEvent) => {
        if (
          !dropdownRef.current?.contains(event.target as HTMLElement) &&
          showDateDropdown
        )
          setShowDateDropdown(false);
      };

      document.addEventListener("mousedown", handleDropdownClick);
      return () => {
        document.removeEventListener("mousedown", handleDropdownClick);
      };
    }
  }, [showDateDropdown]);

  useEffect(() => {
    getInitialFieldValue();
  }, []);

  return (
    <>
      <div
        className={clsx(Styles.datePickerContainer, {
          [Styles.labelLeft]: labelPosition === "left",
        })}
      >
        {label && (
          <label className={Styles.label}>
            <span>{mandatory && "*"}</span>
            {label}
          </label>
        )}
        <div className={Styles.datePickerContent} ref={dropdownRef}>
          <div
            className={Styles.datePickerInput}
            onClick={() => {
              setShowDateDropdown(!showDateDropdown);
            }}
          >
            <input
              className={clsx(Styles.input, {
                [Styles.errorState]: hasError,
                [Styles.disabledState]: disabled,
              })}
              type="text"
              readOnly
              name={name}
              ref={fieldValueRef}
              placeholder={placeholder}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                }
              }}
              disabled={disabled}
            />
            {helperText && (
              <span className={Styles.helperText}>{helperText}</span>
            )}
            <img src={CalendarOutline} />
          </div>
          {showDateDropdown && (
            <div className={Styles.dateTimePicker}>
              <CustomDateRangePicker
                disableMonthSelection={disableMonthSelection}
                disableYearSelection={disableYearSelection}
                type={type}
                setSelectedDateObj={handleChooseDate}
                defaultSelectedDateObj={pickedDate.current}
                showClearDates={showClearDates}
                disablePreviousDates={disablePreviousDates}
                disableDatesAfter={disableDatesAfter}
                disabledDates={disabledDates}
              />
              {type === "single" && showTime && (
                <div className={Styles.timeInput}>
                  Time:
                  <TimeInput
                    setValue={(time: ITimeInputData) => {
                      setTime(time);
                      handleSubmitDates(time);
                    }}
                    defaultTime={
                      selectedDate.current.value
                        ? new Date(selectedDate.current.value)
                        : new Date()
                    }
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DynamicFormDatePicker;
